<template>
  <nav
    class="right_bg close-right-sidebar-16rem z-40 md:right-0 md:block md:top-0 md:bottom-0 md:overflow-y-auto md:flex-row md:flex-nowrap md:overflow-hidden shadow-xl flex flex-wrap items-center justify-between relative md:w-w-2/12 z-10"
    v-bind:style="{ right: toggle_right_Sidebar + 'rem' }"
  >
    <div class="">
      <div class="flex bg-emerald-500 justify-between px-2 py-0 mneminics_mt">
        <div class="righ_side_head_">
          <svg
            class="twobarsRight"
            xmlns="http://www.w3.org/2000/svg"
            width="12"
            height="4"
            viewBox="0 0 12 4"
            fill="none"
            data-v-6a52651e=""
          >
            <path
              d="M11.3333 0H0.666626V1.33333H11.3333V0ZM0.666626 4H11.3333V2.66667H0.666626V4Z"
              fill="#777777"
              data-v-6a52651e=""
            ></path>
          </svg>
          <p class="sidebar-heading">Bar Gauge</p>
        </div>
        <div>
          <svg
            v-on:click="closeNavigation"
            class="cursor-pointer ml-auto mt-0 w-2 left_close_btn"
            style="text-align: right; width: 0.5rem"
            xmlns="http://www.w3.org/2000/svg"
            width="15"
            height="15"
            viewBox="0 0 15 15"
          >
            <path
              id="close_2_"
              data-name="close (2)"
              d="M8.874,7.636,14.715,1.8A.972.972,0,1,0,13.341.421L7.5,6.262,1.659.421A.972.972,0,0,0,.285,1.8L6.126,7.636.285,13.477a.972.972,0,1,0,1.374,1.374L7.5,9.01l5.841,5.841a.972.972,0,0,0,1.374-1.374Zm0,0"
              transform="translate(0 -0.136)"
              fill="#fff"
              class="w-2"
            />
          </svg>
        </div>
      </div>
    </div>

    <div class="pt-0_5 pr-0_5 pl-0_5 pb-0_5 prop_mx_height">
      <MnemonicSelect :widget-id="widgetId" :display-id="displayId" :mnemonic="title" @mnemonic_hange="(val)=>mnemonicChangeHandler(val)" @mnemonic_title="(val)=>changeTitleHandler(val)" @unit_conversion_factor_change="unitConversionChangedHandler"  key="verticalbar_bar"/>
      <!-- <div
        v-on:click="toggle_height_handler_one"
        class="flex justify-between pt-4 pl-5 pb-4 bg-right_"
      >
        <div>
          <p class="sidebar-label lables_with_ico">
            <img src="../../assets/img/genaral_settings.png" alt="" />
            General Settings
          </p>
        </div>
        <div class="d-flex self-center">
          <p v-if="!isExpandFeatureOne"><i class="fas fa-angle-down"></i></p>
          <p v-else><i class="fas fa-angle-up"></i></p>
        </div>
      </div>

      <div
        v-bind:style="{ display: isExpandFeatureOne ? '' : 'none' }"
        class="expand-right-sidebar-feature-one"
      >
        <div class="pb-3">
          <div class="custom-radio-wrap mb-4">
            <div class="flex flex-col mt-3">
              <p class="label_heading">Display name</p>
              <input
                type="text"
                class="h-9 border-0 block w-5/12 fields font-color"
                @input="changeTitleHandler($event)"
                :value="fullName"
              />
            </div>

            <div class="flex flex-col mt-3">
              <p class="label_heading">Mnemonic</p>
              <select
                id="mnemonic"
                class="h-9 border-0 block w-5/12 fields font-color left-0 leading-none"
                @change="mnemonicChangeHandler($event)"
                @mouseover="isHovering = true"
                @mouseout="isHovering = false"
              >
                <option>{{ title }}</option>
                <option :key="index" :value="tag" v-for="(tag, index) in tags">
                  {{ tag }}
                </option>
              </select>
              <span
                class="tooltip text-center"
                v-if="isHovering"
                :class="{ hovering: isHovering }"
              >
                {{ title }}
              </span>
            </div>

            <div class="flex flex-col mt-3">
              <p class="label_heading">Unit</p>
              <input
                type="text"
                disabled
                class="h-9 border-0 block w-5/12 fields font-color"
                :value="unit"
              />
            </div>
          </div>
        </div>
      </div> -->

      <div
        v-on:click="toggle_height_handler_two"
        class="flex bg-black justify-between pt-5 pl-5 pb-4 bg-right_"
      >
        <div>
          <p class="sidebar-label lables_with_ico">
            <img src="../../assets/img/note.png" alt="" />
            Appearance
          </p>
        </div>
        <div class="d-flex self-center">
          <p v-if="!isExpandFeatureTwo"><i class="fas fa-angle-down"></i></p>
          <p v-else><i class="fas fa-angle-up"></i></p>
        </div>
      </div>

      <div
        v-bind:style="{ display: isExpandFeatureTwo ? '' : 'none' }"
        class="expand-right-sidebar-feature-one"
      >
        <div class="pb-5 pt-3">
          <div class="mt-3">
            <label class="w-100 flex font-thin">
              <span class="label_heading"> Text Autofit </span>
              <input
                class="ml-auto leading-tight"
                type="checkbox"
                @click="toggleAutoHandler()"
                :checked="autoFit"
              />
            </label>
          </div>
          <div v-if="!autoFit" class="flex items-center justify-between mt-3">
            <p class="label_heading">Title Font %</p>
            <input
              min="0"
              max="50"
              type="range"
              class="focus:ring-indigo-500 h-9 border-0 block w-5/12"
              @input="fontValueHandler($event)"
              :value="titleSize"
            />
          </div>

          <div v-if="!autoFit" class="flex items-center justify-between mt-3">
            <p class="label_heading">Value Font %</p>
            <input
              min="0"
              max="50"
              type="range"
              class="focus:ring-indigo-500 h-9 border-0 block w-5/12"
              @input="valueFontValueHandler($event)"
              :value="valueSize"
            />
          </div>

          <div v-if="!autoFit" class="flex items-center justify-between mt-3">
            <p class="label_heading">Unit Font %</p>
            <input
              min="0"
              max="50"
              type="range"
              class="focus:ring-indigo-500 h-9 border-0 input-bg block w-5/12 rounded-md"
              @input="unitFontValueHandler($event)"
              :value="unitSize"
            />
          </div>

          <div class="flex items-center justify-between mt-3">
            <p class="label_heading">Title Color</p>
            <input
              type="color"
              min="0"
              max="13"
              id="titleColor"
              class="focus:ring-indigo-500 label_heading h-9 border-0 input-bg block w-5/12 rounded-md font-color"
              @input="changeTitleColorHandler($event)"
              :value="titleColor || '#ffffff'"
            />
          </div>
          <div class="flex items-center justify-between mt-3">
            <p class="label_heading">Value Color</p>
            <input
              type="color"
              min="0"
              max="13"
              id="valueColor"
              class="focus:ring-indigo-500 label_heading h-9 border-0 input-bg block w-5/12 rounded-md font-color"
              @input="changeValueColorHandler($event)"
              :value="valueColor || '#ffffff'"
            />
          </div>
          <div class="flex items-center justify-between mt-3">
            <p class="label_heading">Unit Color</p>
            <input
              type="color"
              min="0"
              max="13"
              id="unitColor"
              class="focus:ring-indigo-500 label_heading h-9 border-0 input-bg block w-5/12 rounded-md font-color"
              @input="changeUnitColorHandler($event)"
              :value="unitColor || '#ffffff'"
            />
          </div>

          <div class="flex flex-col mt-3">
            <p class="label_heading">Decimals</p>
            <input
              type="number"
              min="0"
              max="13"
              class="focus:ring-indigo-500 label_heading h-9 border-0 block w-5/12 fields font-color"
              @input="decimalValueHandler($event)"
              :value="decimalValue"
            />
          </div>
        </div>
      </div>

      <div
        v-on:click="toggle_height_handler_three"
        class="flex bg-black justify-between pt-5 pl-5 pb-4 bg-right_"
      >
        <div>
          <p class="sidebar-label lables_with_ico">
            <img src="../../assets/img/shared_vision.png" alt="" />
            Visibility
          </p>
        </div>
        <div class="d-flex self-center">
          <p v-if="!isExpandFeatureThree"><i class="fas fa-angle-down"></i></p>
          <p v-else><i class="fas fa-angle-up"></i></p>
        </div>
      </div>
      <div
        v-bind:style="{ display: isExpandFeatureThree ? '' : 'none' }"
        class="expand-right-sidebar-feature-one"
      >
        <div class="px-3 pb-5 pt-3">
          <div>
            <div class="form-group-checkbox">
              <label class="w-100 flex font-thin">
                <span class="label_heading"> Title </span>
                <input
                  class="ml-auto leading-tight"
                  type="checkbox"
                  @click="toggleTitleVisibiltyHandler()"
                  :checked="titleDisplay"
                />
              </label>
              <!-- <input
                class="checkbox"
                type="checkbox"
                id="menu1"
                @click="toggleTitleVisibiltyHandler()"
                :checked="titleDisplay"
              />
              <label class="check-label label_heading" for="menu1">Title</label> -->
            </div>
            <div class="form-group-checkbox">
              <label class="w-100 flex font-thin">
                <span class="label_heading"> Value </span>
                <input
                  class="ml-auto leading-tight"
                  type="checkbox"
                  @click="toggleValueVisibiltyHandler()"
                  :checked="valueDisplay"
                />
              </label>
              <!-- <input
                class="checkbox"
                type="checkbox"
                id="menu2"
                @click="toggleValueVisibiltyHandler()"
                :checked="valueDisplay"
              />
              <label class="check-label label_heading" for="menu2">Value</label> -->
            </div>
            <div class="form-group-checkbox">
              <label class="w-100 flex font-thin">
                <span class="label_heading"> Unit </span>
                <input
                  class="ml-auto leading-tight"
                  type="checkbox"
                  @click="toggleUnitVisibiltyHandler()"
                  :checked="unitDisplay"
                />
              </label>
              <!-- <input
                class="checkbox"
                type="checkbox"
                id="menu3"
                @click="toggleUnitVisibiltyHandler()"
                :checked="unitDisplay"
              />
              <label class="check-label label_heading" for="menu3">Unit</label> -->
            </div>
            <div class="form-group-checkbox">
              <label class="w-100 flex font-thin">
                <span class="label_heading"> Border </span>
                <input
                  class="ml-auto leading-tight"
                  type="checkbox"
                  @click="toggleBorderVisibiltyHandler()"
                  :checked="borderDisplay"
                />
              </label>
              <!-- <input
                class="checkbox"
                type="checkbox"
                id="menu4"
                @click="toggleBorderVisibiltyHandler()"
                :checked="borderDisplay"
              />
              <label class="check-label label_heading" for="menu4"
                >Border</label
              > -->
            </div>
            <div class="flex items-center justify-between mt-3">
              <p class="label_heading">Border Color</p>
              <input
                type="color"
                min="0"
                max="13"
                id="borderColor"
                class="focus:ring-indigo-500 label_heading h-9 border-0 input-bg block w-5/12 rounded-md font-color"
                @input="changeBorderColorHandler($event)"
                :value="borderColor || '#ffffff'"
              />
            </div>

            <div class="flex flex-col mt-3">
              <p class="label_heading">Border Width</p>
              <input
                type="number"
                min="0"
                max="13"
                class="focus:ring-indigo-500 label_heading h-9 border-0 block w-5/12 fields font-color"
                @input="changeBorderWidthHandler($event)"
                :value="borderWidth"
              />
            </div>
          </div>
        </div>
      </div>

      <div
        v-on:click="toggle_height_handler_four"
        class="flex bg-black justify-between pt-5 pl-5 pb-4 bg-right_"
      >
        <div>
          <p class="sidebar-label lables_with_ico">
            <img src="../../assets/img/range_.png" alt="" />
            Ranges
          </p>
        </div>
        <div class="d-flex self-center">
          <p v-if="!isExpandFeatureFour"><i class="fas fa-angle-down"></i></p>
          <p v-else><i class="fas fa-angle-up"></i></p>
        </div>
      </div>

      <div
        v-bind:style="{ display: isExpandFeatureFour ? '' : 'none' }"
        class="expand-right-sidebar-feature-one"
      >
        <div class="pb-5 pt-3">
          <!-- <div class="flex flex-col mt-3">
            <p class="label_heading">Interval</p>
            <input
              type="number"
              class="focus:ring-indigo-500 label_heading h-9 border-0 block w-5/12 fields font-color"
              @input="intervalBarGaugeHandler($event)"
              :value="interval"
            />
          </div> -->
          <div class="flex flex-col mt-3">
            <p class="label_heading">Start Range</p>
            <input
              type="number"
              class="focus:ring-indigo-500 label_heading h-9 border-0 block w-5/12 fields font-color"
              @input="startRangeBarGaugeHandler($event)"
              :value="rangeStart"
            />
          </div>
          <div class="flex flex-col mt-3">
            <p class="label_heading">End Range</p>
            <input
              type="number"
              class="focus:ring-indigo-500 label_heading h-9 border-0 block w-5/12 fields font-color"
              @input="endRangeBarGaugeHandler($event)"
              :value="rangeEnd"
            />
          </div>
        </div>
      </div>

      <div
        v-on:click="toggle_height_handler_five"
        class="flex bg-black justify-between pt-5 pl-5 pb-4 bg-right_"
      >
        <div>
          <p class="sidebar-label lables_with_ico">
            <img src="../../assets/img/alert.png" alt="" />
            Alarm
          </p>
        </div>
        <div class="d-flex self-center">
          <p v-if="!isExpandFeatureFive"><i class="fas fa-angle-down"></i></p>
          <p v-else><i class="fas fa-angle-up"></i></p>
        </div>
      </div>
      <div
        v-bind:style="{ display: isExpandFeatureFive ? '' : 'none' }"
        class="expand-right-sidebar-feature-one"
      >
        <div class="pb-3 pt-3">
          <div class="flex items-center justify-between mt-3">
            <label for="blink" class="label_heading">Blink Alert</label>
            <input
              id="blink"
              type="checkbox"
              class="focus:ring-indigo-500 label_heading border-0 block"
              @input="changeBlinkAlert($event)"
              :checked="blinkAlert"
            />
          </div>
          <div class="flex items-center justify-between mt-3">
            <p class="label_heading">Alert Color</p>
            <input
              type="color"
              id="alertColor"
              class="focus:ring-indigo-500 label_heading h-9 border-0 input-bg block w-5/12 font-color"
              @input="changeAlertColor($event)"
              :value="alertColor"
            />
          </div>

          <div class="flex flex-col mt-3">
            <p class="label_heading">Start Range</p>
            <input
              type="number"
              class="focus:ring-indigo-500 label_heading h-9 border-0 block w-5/12 fields font-color"
              @input="changeStartRange($event)"
              :value="alertStartRange"
            />
          </div>

          <div class="flex flex-col mt-3">
            <p class="label_heading">End Range</p>
            <input
              type="number"
              class="focus:ring-indigo-500 label_heading h-9 border-0 block w-5/12 fields font-color"
              @input="changeEndRange($event)"
              :value="alertEndRange"
            />
          </div>

          <!-- <h5 class="label_heading">Add Breakpoint</h5> -->

          <!-- <div
            :key="index"
            v-for="(breakPoint, index) in $store.state.rect.rects[widgetIndex]
              ?.breakPoints"
          >
            <div class="flex flex-col">
              <p class="label_heading">Breakpoint value</p>
              <input
                type="number"
                class="border-0 block fields font-color"
                @input="breakpointValueHandler($event, index)"
              />
            </div>

            <div class="flex items-center justify-between mt-3">
              <p class="label_heading">Breakpoint color</p>
              <input
                type="color"
                class="h-9 border-0 input-bg block w-5/12 rounded-md font-color"
                @input="breakpointColorHandler($event, index)"
              />
            </div>
          </div>

          <div>
            <div class="flex flex-col mt-3">
              <p class="label_heading">Breakpoint value</p>
              <input
                type="number"
                class="h-9 border-0 block w-5/12 fields font-color"
                @input="breakpointValueHandler($event, 0)"
              />
            </div>

            <div class="flex items-center justify-between mt-3">
              <p class="label_heading">Breakpoint color</p>
              <input
                type="color"
                class="h-9 border-0 input-bg block w-5/12 rounded-md font-color"
                @input="breakpointColorHandler($event, 0)"
              />
            </div>
          </div>

          <br />

          <button
            style="margintop: 10px"
            class="bg-green-500 text-white active:bg-pink-600 font-thin uppercase btn_txt rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150 m-auto flex justify-center px-4 py-0.5 w-9/12"
            type="button"
            @click="addBarRangeAndColor()"
          >
            Add
          </button>

          <br /> -->
        </div>
      </div>
      <UpDownButtons
        :isExpandFeatureSix="isExpandFeatureSix"
        :toggle_height_handler_six="toggle_height_handler_six"
        :id="id"
        :displayId="properties.displayId"
        :widgetId="properties.widgetId"
        :closePropertyFile="closeNavigation"
      />
    </div>
  </nav>
</template>

<script>
import UpDownButtons from "../UpDownButtons/UpDownButtons";
import MnemonicSelect from "./properties/MnemonicSelect.vue";
export default {
  name: "Maindash",
  components: {
    UpDownButtons,
    MnemonicSelect
  },
  props: {
    widgetId: String,
    displayId: String,
    toggle_right_Sidebar: {
      type: String,
      default: "",
    },
    toggle_right_margin: {
      type: String,
      default: "",
    },
    id: "",
    widgetIndex: [Number],
    properties: {},
    closeNavigation: Function,
  },
  data() {
    return {
      idx: -1,
      breakPoint: "",
      breakPointColor: "",
      Dtags: [],
      isExpandFeatureOne: true,
      isExpandFeatureTwo: false,
      isExpandFeatureThree: false,
      isExpandFeatureFour: false,
      isExpandFeatureFive: false,
      isExpandFeatureSix: false,
      isHovering: false,
    };
  },
  beforeMount() {
    let rects = this.$store.state.rect.rects;
    if (typeof rects != "undefined" && rects.length > 0) {
      for (let i = 0; i < rects.length; i++) {
        if (
          rects[i].widgetId == this.widgetId &&
          rects[i].displayId == this.displayId
        )
          this.idx = i;
      }
    }
  },
  computed: {
    autoFit() {
      return this.$store.state.rect.rects[this.idx].autoFit;
    },
    tags() {
      // this.activeDisplay = this.$store.state.data.selectedDisplay;
      let display = this.$store.state.disp.selectedDisplay;
      //
      let displayobj = this.$store.state.disp.displays;
      //
      if (displayobj[display]) {
        this.Dtags = displayobj[display].tags;
        return displayobj[display].displayTags;
      } else {
        return [];
      }
      // return displayobj.tags;
    },
    title() {
      //
      //   "tttttttttttttttttttttttttttttte",
      //   this.$store.state.rect.rects
      // );
      return this.$store.state.rect.rects[this.idx].title || "";
    },
    fullName() {
      return this.$store.state.rect.rects[this.idx].fullName || "";
    },
    unit() {
      return this.$store.state.rect.rects[this.idx].unit;
    },
    titleColor() {
      return this.$store.state.rect.rects[this.idx].titleColor ||
        (this.darkDark !== 'white' ? "#ffffff" : "#000000")
    },
    valueColor() {
      return this.$store.state.rect.rects[this.idx].valueColor ||
        (this.darkDark !== 'white' ? "#ffffff" : "#000000")
    },
    unitColor() {
      return this.$store.state.rect.rects[this.idx].unitColor ||
        (this.darkDark !== 'white' ? "#ffffff" : "#000000")
    },
    borderColor() {
      return this.$store.state.rect.rects[this.idx].borderColor || 
        (this.darkDark !== 'white' ? "#ffffff" : "#000000");
    },
    borderWidth() {
      return this.$store.state.rect.rects[this.idx].borderWidth;
    },
    decimalValue() {
      return this.$store.state.rect.rects[this.idx].decimalValue;
    },
    titleDisplay() {
      return this.$store.state.rect.rects[this.idx].titleDisplay;
    },
    valueDisplay() {
      return this.$store.state.rect.rects[this.idx].valueDisplay;
    },
    unitDisplay() {
      return this.$store.state.rect.rects[this.idx].unitDisplay;
    },
    borderDisplay() {
      return this.$store.state.rect.rects[this.idx].borderDisplay;
    },
    interval() {
      return this.$store.state.rect.rects[this.idx].interval;
    },
    rangeStart() {
      return this.$store.state.rect.rects[this.idx].rangeStart;
    },
    rangeEnd() {
      return this.$store.state.rect.rects[this.idx].rangeEnd;
    },
    alertColor() {
      return this.$store.state.rect.rects[this.idx].alertColor || "#ff0000";
    },
    alertStartRange() {
      return this.$store.state.rect.rects[this.idx].alertStartRange;
    },
    alertEndRange() {
      return this.$store.state.rect.rects[this.idx].alertEndRange;
    },
    blinkAlert() {
      return this.$store.state.rect.rects[this.idx].blinkAlert;
    },
    darkDark() {
      return this.$store.state.rect.darkmode;
    },
  },
  methods: {
    unitConversionChangedHandler(conversionFactordet){
      this.$store.dispatch("rect/changeNumericUnitConversionFactor", {
        displayId: this.displayId,
        widgetId: this.widgetId,
        value: conversionFactordet.conversion_factor,
      });
    },
    fontValueHandler(e) {
      this.$store.dispatch("rect/titleFontValue", {
        displayId: this.properties.displayId,
        widgetId: this.properties.widgetId,
        value: e.target.value ? parseInt(e.target.value) : 0,
      });
    },
    valueFontValueHandler(e) {
      this.$store.dispatch("rect/valueFontValue", {
        displayId: this.properties.displayId,
        widgetId: this.properties.widgetId,
        value: e.target.value ? parseInt(e.target.value) : 0,
      });
    },
    unitFontValueHandler(e) {
      this.$store.dispatch("rect/unitFontValue", {
        displayId: this.properties.displayId,
        widgetId: this.properties.widgetId,
        value: e.target.value ? parseInt(e.target.value) : 0,
      });
    },
    toggleAutoHandler() {
      this.$store.dispatch("rect/toggleAutoFit", {
        displayId: this.properties.displayId,
        widgetId: this.properties.widgetId,
      });
    },
    getIndex(state, widgetId, displayId) {
      let index = -1;
      for (let i = 0; i < state.rects.length; i++) {
        if (
          state.rects[i].widgetId == widgetId &&
          state.rects[i].displayId == displayId
        ) {
          index = i;
          return index;
        }
      }
    },
    addBarRangeAndColor() {
      this.$store.dispatch("rect/breakPointChange", {
        displayId: this.properties.displayId,
        widgetId: this.properties.widgetId,
      });
      let idx = this.getIndex(
        this.$store.state.rect,
        this.properties.widgetId,
        this.properties.displayId
      );
      let barBreakPoints = this.$store.state.rect.rects[idx].breakPoints || [];
      barBreakPoints = [
        ...barBreakPoints,
        { value: parseInt(this.breakPoint), color: this.breakPointColor },
      ];

      // sorting
      function compare(a, b) {
        if (parseFloat(a.value) < parseFloat(b.value)) {
          return -1;
        }
        if (parseFloat(a.value) > parseFloat(b.value)) {
          return 1;
        }
        return 0;
      }
      barBreakPoints = barBreakPoints.sort(compare);

      this.$store.dispatch("rect/breakpointAdd", {
        displayId: this.properties.displayId,
        widgetId: this.properties.widgetId,
        isVerticalBar: true,
        value: barBreakPoints,
      });

      this.breakPoint = "";
      this.breakPointColor = "";
    },
    removeBreakPoints() {
      this.$store.dispatch("rect/breakPointChange", {
        displayId: this.properties.displayId,
        widgetId: this.properties.widgetId,
      });

      this.$store.dispatch("rect/breakpointRemove", {
        displayId: this.properties.displayId,
        widgetId: this.properties.widgetId,
      });
    },

    breakpointValueHandler(e, index) {
      this.breakPoint = e.target.value;
      // this.$store.dispatch("rect/breakpointValueHandler", {
      //
      //       displayId: this.properties.displayId,
      // widgetId: this.properties.widgetId,,
      //   value: e.target.value,
      //   breakPointIndex: index
      // });
    },
    breakpointColorHandler(e, index) {
      this.breakPointColor = e.target.value;
      // this.$store.dispatch("rect/breakpointColorHandler", {
      //
      //       displayId: this.properties.displayId,
      // widgetId: this.properties.widgetId,,
      //   value: e.target.value,
      //   breakPointIndex: index
      // });
    },

    changeAlertColor(e) {
      this.$store.dispatch("rect/changeAlertColor", {
        displayId: this.properties.displayId,
        widgetId: this.properties.widgetId,
        value: e.target.value,
      });
    },
    changeBlinkAlert(e) {
      this.$store.dispatch("rect/changeBlinkAlert", {
        displayId: this.properties.displayId,
        widgetId: this.properties.widgetId,
        value: e.target.checked,
      });
    },
    toggleTitleVisibiltyHandler() {
      this.$store.dispatch("rect/toggleTitleVisibility", {
        displayId: this.properties.displayId,
        widgetId: this.properties.widgetId,
      });
    },
    toggleUnitVisibiltyHandler() {
      this.$store.dispatch("rect/toggleUnitVisibility", {
        displayId: this.properties.displayId,
        widgetId: this.properties.widgetId,
      });
    },
    toggleValueVisibiltyHandler() {
      this.$store.dispatch("rect/toggleValueVisibility", {
        displayId: this.properties.displayId,
        widgetId: this.properties.widgetId,
      });
    },
    toggleBorderVisibiltyHandler() {
      this.$store.dispatch("rect/toggleBorderVisibility", {
        displayId: this.properties.displayId,
        widgetId: this.properties.widgetId,
      });
    },
    changeTitleColorHandler(event) {
      this.$store.dispatch("rect/changeTitleColor", {
        displayId: this.properties.displayId,
        widgetId: this.properties.widgetId,
        value: event.target.value,
      });
    },
    changeValueColorHandler() {
      this.$store.dispatch("rect/changeValueColor", {
        displayId: this.properties.displayId,
        widgetId: this.properties.widgetId,
        value: event.target.value,
      });
    },
    changeUnitColorHandler() {
      this.$store.dispatch("rect/changeUnitColor", {
        displayId: this.properties.displayId,
        widgetId: this.properties.widgetId,
        value: event.target.value,
      });
    },

    startRangeBarGaugeHandler(event) {
      this.$store.dispatch("rect/startRangeBarGauge", {
        displayId: this.properties.displayId,
        widgetId: this.properties.widgetId,
        value: Number(event.target.value),
      });
    },
    endRangeBarGaugeHandler(event) {
      this.$store.dispatch("rect/endRangeBarGauge", {
        displayId: this.properties.displayId,
        widgetId: this.properties.widgetId,
        value: Number(event.target.value),
      });
    },
    intervalBarGaugeHandler(event) {
      if (event.target.value > 0) {
        this.$store.dispatch("rect/intervalBarGauge", {
          displayId: this.properties.displayId,
          widgetId: this.properties.widgetId,
          value: event.target.value,
        });
      }
    },
    changeBorderColorHandler(event) {
      this.$store.dispatch("rect/changeBorderColor", {
        displayId: this.properties.displayId,
        widgetId: this.properties.widgetId,
        value: event.target.value,
      });
    },

    changeBorderWidthHandler(event) {
      this.$store.dispatch("rect/changeBorderWidth", {
        displayId: this.properties.displayId,
        widgetId: this.properties.widgetId,
        value: event.target.value,
      });
    },

    changeStartRange(e) {
      this.$store.dispatch("rect/circularAlarmStartRange", {
        displayId: this.properties.displayId,
        widgetId: this.properties.widgetId,
        value: parseInt(e.target.value),
      });
    },
    changeEndRange(e) {
      this.$store.dispatch("rect/circularAlarmEndRange", {
        displayId: this.properties.displayId,
        widgetId: this.properties.widgetId,
        value: parseInt(e.target.value),
      });
    },

    decimalValueHandler(e) {
      if (e.target.value < 14 && e.target.value > -1) {
        this.$store.dispatch("rect/decimalValue", {
          displayId: this.properties.displayId,
          widgetId: this.properties.widgetId,
          value: e.target.value,
        });
      }
    },

    changeTitleHandler(e) {
      // this.$store.dispatch("rect/changeTitle", {
      //   displayId: this.properties.displayId,
      //   widgetId: this.properties.widgetId,
      //   fullName: e.target.value,
      // });
      this.$store.dispatch("rect/changeTitle", e);
    },
    toggle_height_handler_one() {
      if (this.isExpandFeatureOne == true) {
        this.isExpandFeatureOne = false;
      } else {
        this.isExpandFeatureOne = true;
      }
    },
    toggle_height_handler_two() {
      if (this.isExpandFeatureTwo == true) {
        this.isExpandFeatureTwo = false;
      } else {
        this.isExpandFeatureTwo = true;
      }
    },
    toggle_height_handler_three() {
      if (this.isExpandFeatureThree == true) {
        this.isExpandFeatureThree = false;
      } else {
        this.isExpandFeatureThree = true;
      }
    },
    toggle_height_handler_four() {
      if (this.isExpandFeatureFour == true) {
        this.isExpandFeatureFour = false;
      } else {
        this.isExpandFeatureFour = true;
      }
    },
    toggle_height_handler_five() {
      if (this.isExpandFeatureFive == true) {
        this.isExpandFeatureFive = false;
      } else {
        this.isExpandFeatureFive = true;
      }
    },
    toggle_height_handler_six() {
      if (this.isExpandFeatureSix == true) {
        this.isExpandFeatureSix = false;
      } else {
        this.isExpandFeatureSix = true;
      }
    },
    async mnemonicChangeHandler(e) {
      // this.Dtags[this.tags.indexOf(e.target.value)];
      // this.$store.dispatch("rect/barMemonicChange", {
      //   displayId: this.properties.displayId,
      //   widgetId: this.properties.widgetId,
      //   value: this.Dtags[this.tags.indexOf(e.target.value)],
      //   fullName: e.target.value,
      // });
      // this.Dtags[this.tags.indexOf(e.target.value)];
      this.$store.dispatch("rect/barMemonicChange", e);
      this.$store.dispatch("rect/changeUnit", {
        displayId: e.displayId,
        widgetId: e.widgetId,
        value: e.unit,
      });
      // let tagValue = await api.WellServices.getDataForTags({
      //   fields: e.target.value,
      // });
      // alert(JSON.stringify(tagValue.data.rows[0][e.target.value]))
      // this.$store.dispatch("rect/barValueUpdate", {
      //   displayId: this.properties.displayId,
      //   widgetId: this.properties.widgetId,
      //   value: tagValue.data.rows[0][e.target.value],
      // });
    },
    async getTags() {
      // let tagsResponse = await api.WellServices.getColumnsOfDepthLog();
      // this.tags = tagsResponse.data;
    },

    dark() {
      this.darkMode = true;
      this.$emit("dark");
    },
    light() {
      this.darkMode = false;
      this.$emit("light");
    },
  },
  mounted() {
    this.getTags();

    this.$store.subscribe((mutation, state) => {
      if (mutation.type == "rect/DARK_MODE") {
        if (this.$store.state.rect.darkmode) {
          this.light();
        } else {
          this.dark();
        }
      }
    });
  },
};
</script>

<style scoped src="../../assets/styles/propstyle.css"></style>
